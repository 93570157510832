<template>
  <div class="tree1 w-1920">
    <Header />
    <Search :msg="$t('floatNav.Cart')" />
    <div class="tree w-1200">
      <div class="tree-title">
        <input type="checkbox" ref="businessInp" @click="getBusinessWhole" />
        {{ $t('common.select_all') }}
        <div class="shangpin">{{ $t('common.product') }}</div>
        <div class="danjia">{{ $t('common.Unit_price') }}</div>
        <div class="shuliang">{{ $t('common.quantity') }}</div>
        <div class="xiaoji">{{ $t('common.subtotal') }}</div>
        <div class="caozuo">{{ $t('label.Controls') }}</div>
      </div>
      <!-- 列表 -->
      <Loading v-if="isLoading" />
      <div v-else>
        <div class="tishi" v-if="data == ''">{{ tishi }}</div>
        <div class="tree-merchant" v-for="(item, index) of data" :key="index">
          <!-- 店铺名称 -->
          <div class="tree-merchant-name">
            <input type="checkbox" ref="business" v-model="business" :value="item.storeId" @click="getBusinessId(item, index)" />
            <div class="name">{{ item.storeName }}</div>
          </div>
          <!-- 商品信息 -->
          <div class="tree-goods" v-for="(goods, indexs) of item.cartJoinList" :key="indexs">
            <div class="checkbox">
              <input type="checkbox" :ref="`goods${goods.cartSkuId}`" v-model="goodsInp" :value="goods.cartSkuId" @click="getGoodsDetails(item, index, goods, indexs)" />
            </div>

            <!-- 图片 -->
            <div class="goods-pic">
              <Uimage :src="goods.defaultImg" alt="" />
            </div>
            <!-- 介绍 -->
            <div class="goods-jieshao">
              <div class="goods-mingcheng" @click="detail(goods.storeId, goods.spuId)">
                {{ goods.name }}
              </div>
              <!-- 商品属性 -->
              <div class="goods-shuxing" v-for="(q, c) in goods.saleAttrCode" :key="c">
                <div v-if="q.attrName">{{ q.attrName }}：</div>
                <div v-if="q.attrValue" v-html="q.attrValue"></div>
              </div>
            </div>
            <!-- 单价 -->
            <div class="goods-danjia1">￥{{ Number(goods.oldPrice).toFixed(2) }}</div>
            <!-- 增加数量 -->
            <div class="goods-shuliang1">
              <div class="goods-shuliang1-num">
                <div class="L" @click="reduce(goods, index, indexs)">
                  <i class="el-icon-minus"></i>
                </div>
                <div class="C">
                  <input type="value" :value="goods.skuCount" disabled="false" name="goodRadio" />
                </div>
                <div class="R" @click="add(goods, index, indexs)">
                  <i class="el-icon-plus"></i>
                </div>
              </div>
              <!-- <div class="youhuo">
                {{ goods.inventory == '0' ? '无货' : '有货' }}
              </div> -->
            </div>
            <div class="goods-xiaoji1">￥{{ Number(goods.oldPrice * goods.skuCount).toFixed(2) }}</div>
            <div class="goods-caozuo1">
              <div class="shan" @click="del(goods)">{{ $t('common.delete') }}</div>
              <div class="yiru" v-if="goods.smcsFlag == '0'" @click="guanzhu(goods)">{{ $t('common.Shift_to_focus') }}</div>
              <div class="yiru" v-if="goods.smcsFlag == '1'" @click="showGuanZhu(goods)">{{ $t('common.Followed') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="jiesuan w-1920">
      <div class="tree-bottom_C w-1200">
        <div class="jiesuan_L">
          <input type="checkbox" v-model="whole" @click="getBusinessWhole" />
          <div class="quanxuan">{{ $t('common.select_all') }}</div>
          <div class="shanchu" @click="delall">{{ $t('common.shortmessage-014') }}</div>
          <!-- <div class="yiru">移入关注</div> -->
        </div>
        <div class="jiesuan_R">
          <div class="yixuanze">
            {{ $t('common.Selected') }}<span class="shu">{{ allnum }}</span
            >{{ $t('common.Piece_of_product') }}
          </div>
          <img class="img" src="../../assets/order/shangjiantou.png" alt="" />
          <div class="zongjia">
            总价：<span class="money">￥{{ allprice }}</span>
          </div>
          <div class="tijiao" @click="tobuy">{{ $t('common.Go_to_Result') }}</div>
        </div>
      </div>
    </div>
    <!-- <Baoyou /> -->
    <div class="kongbai"></div>
    <Foot />
    <Cart ref="Cart" />
  </div>
</template>
<script>
import Header from "@/components/header.vue";
import Foot from "@/components/foot.vue";
import Search from "@/components/order/search.vue";
import Baoyou from "@/components/order/baoyou.vue";
import { getCarts } from "@/api/cart";
import { get, post } from '@/utils/request';
import { setCookie, getCookie, delCookie } from "@/utils/cookie";
import { confirmOrder } from "@/utils/pageUtils.js";
import { toDecimal2 } from "@/utils/numberUtils.js";
import { submitOrderTemp } from "@/api/order.js";
import Cart from "@/components/cart.vue";

export default {
  components: {
    Header,
    Foot,
    Search,
    Baoyou,
    Cart,
  },
  fliters: {
    ji(value) {
      let newVal = parseFloat(value).toFixed(2);
      return newVal;
    },
  },
  data() {
    return {
      num: 1,
      allnum: 0,
      allprice: "0.00",
      data: [],
      // 存储商家的id
      business: [],
      // 购物车id
      goodsInp: [],
      // 用作全选的状态
      whole: false,
      //会员id
      memberId: "",
      isLoading: true,
      tishi: this.$t('common.Its_empty'),
    };
  },

  created() {
    this.getlist();
    this.getmember();
  },
  computed: {
    getDataLength() {
      return this.data.length;
    },

    getBusinessLength() {
      return this.business.length;
    },

    getGoodsInpLength() {
      return this.goodsInp.length;
    },
  },

  methods: {
    detail(storeId, spuId) {
      const { href } = this.$router.resolve({
        name: "detail",
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
      });
      window.open(href, "_blank");
    },
    //结算
    tobuy() {
      let orderTemp = [];
      let cartSkuId = [];
      let spulist = [];
      let cartlist = [];
      let len = this.data1.length;
      for (let i = 0; i < len; i++) {
        this.data1[i].cartJoinList.forEach((res) => {
          this.goodsInp.forEach((req) => {
            if (res.cartSkuId == req) {
              spulist.push({
                name: res.name,
                storeId: res.storeId,
                prodCatId: res.prodCatId,
                batchNumber: res.batchNumber,
              });
            }
          });
        });
      }
      this.data1.forEach((res) => {
        for (let i = 0; i < res.cartJoinList.length; i++) {
          spulist.forEach((rew) => {
            if (res.cartJoinList[i].name == rew.name && res.cartJoinList[i].prodCatId == rew.prodCatId && res.cartJoinList[i].storeId == rew.storeId && res.cartJoinList[i].batchNumber == rew.batchNumber) {
              cartlist.push(res.cartJoinList[i].batchNumber);
            }
          });
        }
      });
      // this.data1.forEach((i)=>{
      //   for (let t = 0; t < i.cartJoinList.length; t++) {
      //     i.cartJoinList[t].skuCount = 1;
      //     for (let k = t+1; k < i.cartJoinList.length; k++) {
      //       if (i.cartJoinList[t].name == i.cartJoinList[k].name&&i.cartJoinList[t].prodCatId == i.cartJoinList[k].prodCatId&&i.cartJoinList[t].storeId == i.cartJoinList[k].storeId&&i.cartJoinList[t].batchNumber == i.cartJoinList[k].batchNumber) {
      //         i.cartJoinList[t].skuCount = 0;
      //       }
      //     }
      //   }
      // })
      let cartlist1 = new Set(cartlist);
      for (let i = 0; i < len; i++) {
        this.data1[i].cartJoinList.forEach((res) => {
          cartlist1.forEach((req) => {
            if (res.batchNumber == req) {
              orderTemp.push({
                skuId: res.skuId,
                num: res.skuCount,
                value: res.contentValue,
                storeId: res.storeId,
                remark: res.remark,
                batchNumber: res.batchNumber,
              });
              cartSkuId.push({
                cartSkuId: res.batchNumber,
              });
            }
          });
        });
      }
      if (orderTemp == "") {
        this.$message.warning({
          message: this.$t('message.shortmessage-015'),
        });
        return;
      }
      //根据skuId去重
      // let hash = {};
      // const data2 = orderTemp.reduce((preVal, curVal) => {
      //   hash[curVal.skuId]
      //     ? ''
      //     : (hash[curVal.skuId] = true && preVal.push(curVal));
      //   return preVal;
      // }, []);

      submitOrderTemp(orderTemp).then((res) => {
        this.$router.push({
          path: "/settlement",
          query: {
            id: cartSkuId,
          },
        });
      });
    },
    //获取会员id
    getmember() {
      get("api/member/selMemberInfo").then((res) => {
        this.memberId = res.data.data.memberId;
      });
    },
    //获取列表
    getlist() {
      get("api/cart/page").then((res) => {
        this.data = JSON.parse(JSON.stringify(res.data.data));
        this.data1 = res.data.data;
        this.data.forEach((rew) => {
          rew.cartJoinList.forEach((req) => {
            req.saleAttrCode = eval("(" + req.saleAttrCode + ")");
            req.saleAttrCode.forEach((k, index) => {
              if (k.attrName == "浓度范围") {
                req.saleAttrCode.splice(index, 1);
              }
            });
            if (req.contentValue != "0mol/mol" && req.contentValue != "0ppm") {
              if (req.contentValue.indexOf("mol/mol") != -1) {
                req.saleAttrCode.push({
                  attrName: "确认含量",
                  attrValue: req.contentValue.replace("mol/mol", "X10<sup>-6</sup>mol/mol"),
                });
              } else {
                req.saleAttrCode.push({
                  attrName: "确认含量",
                  attrValue: req.contentValue,
                });
              }
            }
          });
        });
        this.data.forEach((i) => {
          for (let q = 0; q < i.cartJoinList.length; q++) {
            i.cartJoinList[q].remark = eval("(" + i.cartJoinList[q].remark + ")");
            if (i.cartJoinList[q].skuCount == 0) {
              i.cartJoinList[q].skuCount = 1;
            }
            for (let j = q + 1; j < i.cartJoinList.length; j++) {
              if (i.cartJoinList[q].remark == "1" && i.cartJoinList[j].remark == "1") {
                if (i.cartJoinList[q].name == i.cartJoinList[j].name && i.cartJoinList[q].storeId == i.cartJoinList[j].storeId && i.cartJoinList[q].prodCatId == i.cartJoinList[j].prodCatId && i.cartJoinList[q].batchNumber == i.cartJoinList[j].batchNumber) {
                  for (let p = 0; p < i.cartJoinList[j].saleAttrCode.length; p++) {
                    if (i.cartJoinList[j].saleAttrCode[p].attrName == "多组分" || i.cartJoinList[j].saleAttrCode[p].attrName == "气体组分" || i.cartJoinList[j].saleAttrCode[p].attrName == "确认含量") {
                      i.cartJoinList[q].saleAttrCode.push(i.cartJoinList[j].saleAttrCode[p]);
                      Object.assign(i.cartJoinList[q], { isTrue_num: "true" });
                    }
                  }
                }
              }
            }
          }
        });
        this.data.forEach((i) => {
          for (let q = 0; q < i.cartJoinList.length; q++) {
            for (let j = q + 1; j < i.cartJoinList.length; j++) {
              if (i.cartJoinList[q].remark == "1" && i.cartJoinList[j].remark == "1") {
                if (i.cartJoinList[q].name == i.cartJoinList[j].name && i.cartJoinList[q].storeId == i.cartJoinList[j].storeId && i.cartJoinList[q].prodCatId == i.cartJoinList[j].prodCatId && i.cartJoinList[q].batchNumber == i.cartJoinList[j].batchNumber) {
                  i.cartJoinList.splice(j, 1);
                }
              }
            }
          }
        });
        this.data.forEach((i) => {
          for (let q = 0; q < i.cartJoinList.length; q++) {
            for (let j = q + 1; j < i.cartJoinList.length; j++) {
              if (i.cartJoinList[q].remark == "1" && i.cartJoinList[j].remark == "1") {
                if (i.cartJoinList[q].name == i.cartJoinList[j].name && i.cartJoinList[q].storeId == i.cartJoinList[j].storeId && i.cartJoinList[q].prodCatId == i.cartJoinList[j].prodCatId && i.cartJoinList[q].batchNumber == i.cartJoinList[j].batchNumber) {
                  i.cartJoinList.splice(j, 1);
                }
              }
            }
          }
        });
        this.data.forEach((i) => {
          for (let q = 0; q < i.cartJoinList.length; q++) {
            for (let j = q + 1; j < i.cartJoinList.length; j++) {
              if (i.cartJoinList[q].remark == "1" && i.cartJoinList[j].remark == "1") {
                if (i.cartJoinList[q].name == i.cartJoinList[j].name && i.cartJoinList[q].storeId == i.cartJoinList[j].storeId && i.cartJoinList[q].prodCatId == i.cartJoinList[j].prodCatId && i.cartJoinList[q].batchNumber == i.cartJoinList[j].batchNumber) {
                  i.cartJoinList.splice(j, 1);
                }
              }
            }
          }
        });
        this.isLoading = false;
      });
    },
    //增加
    add(e, index, indexs) {
      this.data[index].cartJoinList[indexs].skuCount++;
      post("api/cart/updateCart", {
        cartSkuId: e.cartSkuId,
        skuCount: this.data[index].cartJoinList[indexs].skuCount,
      }).then((res) => {
        this.getlist();
      });
      this.sum();
    },
    //减少
    reduce(e, index, indexs) {
      this.data[index].cartJoinList[indexs].skuCount--;
      if (this.data[index].cartJoinList[indexs].skuCount <= 1) {
        this.data[index].cartJoinList[indexs].skuCount = 1;
      }
      post("api/cart/updateCart", {
        cartSkuId: e.cartSkuId,
        skuCount: this.data[index].cartJoinList[indexs].skuCount,
      }).then((res) => {
        this.getlist();
      });
      this.sum();
    },
    //合计
    sum() {
      let len = this.data.length;
      let len1 = this.business.length;
      let newArr = [];
      for (let i = 0; i < len; i++) {
        for (let j = 0; j < len1; j++) {
          if (this.data[i].storeId == this.business[j]) {
            this.data[i].cartJoinList.forEach((w) => {
              newArr.push(w);
            });
          }
        }
        this.data[i].cartJoinList.forEach((res) => {
          this.goodsInp.forEach((req) => {
            if (res.cartSkuId == req) {
              newArr.push(res);
            }
          });
        });
      }
      this.allprice = 0;
      newArr = Array.from(new Set(newArr));
      this.allnum = newArr.length;
      newArr.forEach((res) => {
        this.allprice += res.oldPrice * res.skuCount;
      });
      this.allprice = toDecimal2(this.allprice);
    },
    //批量删除
    delall() {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: this.$t('button.Confirm'),
        cancelButtonText: this.$t('button.Cancel'),
        type: "warning",
      }).then(() => {
        this.isLoading = true;
        let cartlist = [];
        let spulist = [];
        this.data1.forEach((res) => {
          for (let i = 0; i < res.cartJoinList.length; i++) {
            this.goodsInp.forEach((req) => {
              if (res.cartJoinList[i].cartSkuId == req) {
                spulist.push(res.cartJoinList[i].spuId);
              }
            });
          }
        });
        this.data1.forEach((res) => {
          for (let i = 0; i < res.cartJoinList.length; i++) {
            spulist.forEach((rew) => {
              if (res.cartJoinList[i].spuId == rew) {
                cartlist.push(res.cartJoinList[i].batchNumber);
              }
            });
          }
        });
        post("api/cart/deleteCartSome", {
          batchNumbers: cartlist.toString(),
        }).then((res) => {
          this.getlist();
          this.isLoading = false;
          if (res.data.msg == this.$t('message.Successful_operation')) {
            this.$message.success({
              message: this.$t('message.Successfully_deleted'),
            });
            // 重新获取 购物车数量
            this.$refs.Cart.getcart();
          } else {
            this.$message.error({
              message: this.$t('message.Deletion_failure'),
            });
          }
        });
      });
    },
    //删除单个
    del(e) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: this.$t('button.Confirm'),
        cancelButtonText: this.$t('button.Cancel'),
        type: "warning",
      }).then(() => {
        if (e.isTrue_num == "true") {
          let cartlist = [];
          this.data1.forEach((i) => {
            for (let q = 0; q < i.cartJoinList.length; q++) {
              if (e.spuId == i.cartJoinList[q].spuId) {
                cartlist.push(i.cartJoinList[q].batchNumber);
              }
            }
          });
          this.isLoading = true;
          post("api/cart/deleteCartSome", {
            batchNumbers: cartlist.toString(),
          }).then((res) => {
            this.getlist();
            this.isLoading = false;
            if (res.data1.msg == this.$t('message.Successful_operation')) {
              this.$message.success({
                message: this.$t('message.Successfully_deleted'),
              });
              // 重新获取 购物车数量
              this.$refs.Cart.getcart();
            } else {
              this.$message.error({
                message: this.$t('message.Deletion_failure'),
              });
            }
          });
        } else {
          let len = this.data1.length;
          for (let i = 0; i < len; i++) {
            let len1 = this.data1[i].cartJoinList.length;
            for (let j = 0; j < len1; j++) {
              if (e.batchNumber == this.data1[i].cartJoinList[j].batchNumber) {
                this.data1[i].cartJoinList.splice(j, 1);
                post("api/cart/deleteCart", {
                  batchNumbers: e.batchNumber,
                }).then((res) => {
                  if (res.data.msg == this.$t('message.Successful_operation')) {
                    this.getlist();
                    this.$message.success({
                      message: this.$t('message.Successfully_deleted'),
                    });
                    // 重新获取 购物车数量
                    this.$refs.Cart.getcart();
                  } else {
                    this.$message.error({
                      message: this.$t('message.Deletion_failure'),
                    });
                  }
                });
              }
            }
            //报错cartJoinList未定义，但不影响使用
            if (this.data1[i].cartJoinList.length == 0) {
              this.data1.splice(i, 1);
            }
          }
        }
      });
    },
    //移入关注
    guanzhu(e) {
      post("api/collectspu/insertshopcollectsup", {
        spuId: e.spuId,
        spuImg: e.defaultImg,
        spuName: e.name,
      }).then((res) => {
        if (res.data.msg == this.$t('message.Successful_operation')) {
          this.$message.success({
            message: this.$t('message.Focus_on_success'),
          });
          this.getlist();
        } else {
          this.$message.error({
            message: this.$t('message.Operation_failure'),
          });
        }
      });
    },
    // 跳转到关注页面
    showGuanZhu(e) {
      this.$router.push({
        name: "wares",
      });
    },
    // 获取全部商家和全部商品
    getBusiness() {
      if (this.$refs.businessInp.checked) {
        this.data.forEach((res, index) => {
          this.business.push(res.storeId);
          this.duplicateRemoval();
          res.cartJoinList.forEach((req, index) => {
            this.goodsInp.push(req.cartSkuId);
            this.duplicateRemovalGoodsId();
          });
        });
      } else {
        this.business = [];
        this.goodsInp = [];
      }
      this.sum();
    },

    // 点击全选时获取全部的商家和商品
    getBusinessWhole() {
      if (!this.whole) {
        this.$refs.businessInp.checked = true;
        this.whole = true;
        this.getBusiness();
      } else {
        this.$refs.businessInp.checked = false;
        this.whole = false;
        this.getBusiness();
      }
    },

    // 当前商家选中时，获取商家下的子商品
    // 当前商家取消选中时，取消全选，删除取消选中的商品
    getBusinessId(item, index) {
      if (this.$refs.business[index].checked) {
        this.business.push(item.storeId);
        this.duplicateRemoval();
        // 所有的商家选中时，全选按钮选中
        let isShole = this.getBusinessLength === this.getDataLength;
        if (isShole) {
          this.whole = false;
          this.getBusinessWhole();
        }
        this.getGoodsId(item);
        this.duplicateRemovalGoodsId();
      } else {
        this.duplicateRemovalGoodsId();
        this.removeGoodsId(item);
        this.filterBusiness(item.storeId);
        this.$refs.businessInp.checked = false;
        this.whole = false;
      }
      this.sum();
    },

    // 去掉重复的商家
    duplicateRemoval() {
      if (this.getBusinessLength !== 0) {
        this.business = Array.from(new Set(this.business));
      }
    },

    // 商家未选中时删除商家
    filterBusiness(id) {
      this.business = this.business.filter((item) => {
        return item !== id;
      });
    },

    // 选中当前商品
    getGoodsDetails(item, indexs, good, index) {
      if (this.$refs[`goods${good.cartSkuId}`][0].checked) {
        this.goodsInp.push(good.cartSkuId);
        this.duplicateRemovalGoodsId();
        if (this.getBusinessGoodsLength(item)) {
          this.$refs.business[indexs].checked = true;
          this.getBusinessId(item, indexs);
        } else {
          this.$refs.business[indexs].checked = false;
        }
      } else {
        //this.allprice -= good.skuCount * good.oldPrice;
        this.duplicateRemovalGoodsId();
        this.filterGetGoodsId(good.cartSkuId);
        this.$refs.business[indexs].checked = false;
        this.$refs.businessInp.checked = false;
        this.whole = false;
        if (!this.getBusinessGoodsLength(item)) {
          this.business.forEach((res, index) => {
            if (res === item.storeId) {
              this.business.splice(index, 1);
            }
          });
        }
      }
      this.sum();
    },

    // 获取当前商家下的所有商品
    getGoodsId(item) {
      item.cartJoinList.forEach((res, index) => {
        this.goodsInp.push(res.cartSkuId);
      });
      this.sum();
    },

    // 清空当前商家下的所有商品
    removeGoodsId(item) {
      item.cartJoinList.forEach((res, index) => {
        this.goodsInp.forEach((req, index) => {
          if (res.cartSkuId === req) {
            this.goodsInp.splice(index, 1);
          }
        });
      });
      this.sum();
    },

    // 商品未选中时删除该商品
    filterGetGoodsId(id) {
      this.goodsInp = this.goodsInp.filter((item) => {
        return item !== id;
      });
      this.sum();
    },

    // 去掉重复的商品
    duplicateRemovalGoodsId() {
      if (this.getGoodsInpLength !== 0) {
        this.goodsInp = Array.from(new Set(this.goodsInp));
      }
    },

    // 判断当前商品是否全部选中
    getBusinessGoodsLength(item) {
      let totalCount = 0;
      let isLen = false;
      item.cartJoinList.forEach((res, index) => {
        this.goodsInp.forEach((req, index) => {
          if (res.cartSkuId === req) {
            totalCount = totalCount + 1;
          }
        });
      });

      if (item.cartJoinList.length === totalCount) {
        isLen = true;
      }
      this.sum();

      return isLen;
    },

    getGoodsCart() {},
  },
};
</script>
<style lang="scss" scoped>
.checkbox {
  width: 30px;
}
.attr-list {
  min-height: 10px;
  color: #9c9c9c;
}
.attr-list > li {
  margin-bottom: 5px;
}
.attr-label {
  float: left;
}
.custom-wrapper {
  margin-left: 80px;
}
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.tishi {
  width: 100%;
  text-align: center;
  // margin-top: 150px;
  height: 175px;
  line-height: 175px;
  font-size: 24px;
  color: rgb(139, 135, 135);
}
.tree1 {
  width: 100%;
  background: #f5f5f5;
  .tree {
    width: 1236px;
    margin: 0 auto;
    background: #fff;
    padding: 50px 40px 70px 35px;
    .tree-title {
      display: flex;
      align-items: center;
      padding: 10px 0 10px 10px;
      background: #f3f3f3;
      border: 1px solid #e9e9e9;
      .shangpin {
        width: 550px;
        /*margin-left: 140px;*/
        text-align: center;
      }
      .danjia {
        width: 108px;
        text-align: center;
      }
      .shuliang {
        width: 162px;
        text-align: center;
      }
      .xiaoji {
        text-align: center;
        width: 100px;
      }
      .caozuo {
        text-align: center;
        flex: 1;
      }
    }
    .tree-merchant {
      margin: 10px 0px;
      .tree-merchant-name {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-bottom: 2px solid #999;
      }
      .tree-goods {
        font-size: 14px;
        color: #4c4c4c;
        display: flex;
        padding: 10px 0;
        min-height: 200px;
        border: 1px solid #e4e4e4;
        padding-left: 10px;
        .goods-pic {
          width: 150px;
          height: 150px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .goods-jieshao {
          width: 400px;
          padding: 10px 10px 0 10px;
          margin-left: 8px;
          overflow: hidden;
          .goods-mingcheng {
            color: #333;
            height: 37px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            cursor: pointer;
          }
          .goods-shuxing {
            word-wrap: break-word;
            width: 100%;
            /*width: 600px;*/
            margin-left: 8px;
            color: #9a9a9a;
            display: flex;
          }
        }
        .goods-xinghao {
          width: 180px;
          padding-top: 15px;
          margin-left: 20px;
          .goods-xinghao-list {
            display: flex;
            margin-top: 5px;
            color: #9c9c9c;
            &-r {
              margin-left: 5px;
            }
          }
        }
        .goods-danjia1 {
          width: 120px;
          padding-top: 20px;
          text-align: center;
        }
        .goods-shuliang1 {
          width: 150px;
          padding-top: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .youhuo {
            color: #a8a8a8;
            margin-top: 10px;
          }
          .wuhuo {
            color: #e83f38;
          }
          &-num {
            display: flex;
            align-items: center;
            .L {
              cursor: pointer;
            }
            .R {
              cursor: pointer;
              margin-left: 10px;
            }
            .C {
              width: 50px;
              margin-left: 10px;
              input {
                width: 20px;
                border: none;
                outline: none;
                text-align: center;
                padding: 0 10px;
                margin-bottom: 10px;
                background: #fff;
              }
            }
          }
        }
        .goods-xiaoji1 {
          width: 120px;
          padding-top: 20px;
          text-align: center;
        }
        .goods-caozuo1 {
          width: 100px;
          padding-top: 20px;
          text-align: center;
          color: #343434;
          .shan {
            cursor: pointer;
          }
          .yiru {
            cursor: pointer;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .jiesuan {
    width: 100%;
    height: 100px;
    background: #fff;
    .tree-bottom_C {
      width: 1150px;
      height: 80px;
      margin: 10px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .jiesuan_L {
        margin-left: 48px;
        display: flex;
        align-items: center;
        .quanxuan {
          padding-top: 5px;
          color: #343434;
        }
        .shanchu {
          margin-left: 20px;
          color: #484848;
          padding-top: 5px;
          cursor: pointer;
        }
        .shanchu:hover {
          color: red;
          border-bottom: 1px solid red;
        }
        .yiru {
          margin-left: 20px;
          color: #484848;
          padding-top: 5px;
          cursor: pointer;
        }
        .yiru:hover {
          border-bottom: 1px solid #484848;
        }
      }
      .jiesuan_R {
        font-size: 14px;
        color: #989898;
        display: flex;
        align-items: center;
        .yixuanze {
          .spen {
            color: #1a50fb;
          }
        }
        .img {
          width: 15px;
          height: 9px;
          margin-left: 10px;
        }
        .zongjia {
          margin-left: 40px;
          .money {
            color: #1b50fe;
          }
        }
        .tijiao {
          width: 84px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          background: #1850ff;
          color: #fff;
          border-radius: 5px;
          margin-left: 17px;
          cursor: pointer;
        }
      }
    }
  }
  input {
    appearance: none;
    outline: none;
    margin-top: 10px;
    padding: 0;
    margin-right: 10px;
  }
  input[type="checkbox"] {
    position: relative;
    width: 20px;
    height: 20px;
    background: #d5d5d5;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    &:checked::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      color: #333;
      text-align: center;
      line-height: 18px;
      background: url("https://static.98ep.com/img/BigCompany/a.png") no-repeat center;
    }
  }

  input[type="checkbox"]:checked::after {
    content: "";
    background-color: #037df3;
    border-color: #037df3;
    background-color: #037df3;
  }
  input[type="checkbox"]:checked::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 5px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    z-index: 1;
  }
  button {
    margin-top: 20px;
  }
}
</style>
